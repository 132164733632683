// libraries
import React from 'react';
import './Footer.css'

class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
        <div style={{width:'100%'}}>
          <hr/>
          <div className='footer-container'>
            <div>
              made with 🩷 and react by mira and <a target="_blank" href="https://www.linkedin.com/in/the-bill-tang/">bill</a> © 2024
            </div>
          </div>
        </div>
    )
  }
}

export default Footer;
